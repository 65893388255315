<template>
  <div
    v-if="visible"
    class="asf-cookies-notice"
    role="dialog"
    :aria-label="`${$t('accessibility.group.common.cookiesNotice')}`"
  >
    <AsfWrapper class="asf-cookies-notice-wrapper" type="section" tag="div">
      <div class="asf-notice-text-wrapper">
        <p class="asf-notice-text">
          <slot />
          <AsfLink class="asf-notice-link hover:text-white" :link="path" target="_blank">
            {{ linkText }}
          </AsfLink>
        </p>
      </div>

      <div class="asf-button-group">
        <AsfButton
          class="asf-button-outline asf-notice-button asf-notice-accept"
          :aria-label="`${$t('cookiesNotice.acceptAll')}`"
          @click="handleConfirm"
        >
          {{ $t('cookiesNotice.acceptAll') }}
        </AsfButton>
        <AsfButton
          class="asf-button-simple asf-notice-button asf-notice-without-accept text-white"
          :aria-label="`${$t('cookiesNotice.acceptWithout')} ${$t('cookiesNotice.tracking')}`"
          @click="handleConfirmNoTracking"
        >
          {{ $t('cookiesNotice.acceptWithout') }}
          <br />
          {{ $t('cookiesNotice.tracking') }}
        </AsfButton>
      </div>
    </AsfWrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AsfCookiesNoticeProps } from '@ui/types'

export default defineComponent({
  name: 'AsfCookiesNotice',
  emits: ['confirmed:cookie-notice', 'confirmed:cookie-notice-no-tracking'],
  props: {
    visible: { type: Boolean as PropType<AsfCookiesNoticeProps['visible']> },
    /** Defines href for Privacy Policy link */
    path: { type: String as PropType<AsfCookiesNoticeProps['path']>, default: '' },
    /** Defines text for Privacy Policy link */
    linkText: { type: String as PropType<AsfCookiesNoticeProps['linkText']>, default: '' }
  },
  setup(_props, { emit }) {
    const handleConfirm = () => emit('confirmed:cookie-notice')
    const handleConfirmNoTracking = () => emit('confirmed:cookie-notice-no-tracking')

    return { handleConfirm, handleConfirmNoTracking }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/CookiesNotice/CookiesNotice.css';
</style>
